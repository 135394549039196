"use client";

import { ScoreTickerContext } from "@/features/score-ticker/context/score-ticker-context";
import { useContext, useEffect } from "react";

export const UpdateTicker = ({ roundId }: { roundId: number }) => {
  const { setRoundId } = useContext(ScoreTickerContext);

  useEffect(() => {
    setRoundId(roundId);
  }, [setRoundId, roundId]);

  return <></>;
};
