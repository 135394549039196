import styles from "./score-ticker.module.scss";
import Link from "next/link";
import Image from "next/image";
import { format } from "date-fns";
import { TeamLogo } from "@/common/components/image/team-logo";

export default function ScoreTickerMatch({ match }) {
  let matchStatus,
    matchClass = "";
  if (match.status == "C") {
    matchStatus = "Full time";
  } else if (match.status == "L") {
    switch (match.middle) {
      case "QT":
        matchStatus = "Quarter time";
        matchClass = styles.statusBreak;
        break;
      case "HT":
        matchStatus = "Half time";
        matchClass = styles.statusBreak;
        break;
      case "3QT":
        matchStatus = "3 Quarter time";
        matchClass = styles.statusBreak;
        break;
      case "FT":
        matchStatus = "Full time";
        matchClass = styles.statusBreak;
        break;
      default:
        matchStatus = `Live ${match.middle} ${Math.floor(
          match.quarter_duration / 60
        )}'`;
        matchClass = styles.statusLive;
        break;
    }
  } else {
    const matchDate = new Date(match.utc_time * 1000);
    matchStatus = format(matchDate, "EEE d MMM h:mma");
  }

  return (
    <Link key={`matchLink${match.id}`} href={`/match/${match.id}`}>
      <div
        className={`text-[11px] bg-white flex-col border-r border-l-gray-400 h-full w-[123px] pl-2 pr-2 pt-1 pb-1 hover:bg-slate-200 hover:cursor-pointer`}
      >
        <div
          className={[
            styles.matchStatus,
            matchClass,
            styles.statusLiveFetching,
          ].join(" ")}
        >
          {matchStatus}
        </div>
        <div className={styles.matchVenue}>{match.ground_name_full}</div>
        <div className={styles.matchTeamScorelines}>
          <div
            className={[
              styles.matchTeamScoreline,
              match.middle == "df" ? styles.winner : "",
            ].join(" ")}
          >
            <div className={`w-4 h-4`}>
              <TeamLogo teamAbbrev={match.home_team} width={16} height={16} />
            </div>
            <div className={styles.matchTeamName}>{match.home_team}</div>
            <div className={styles.matchTeamScore}>
              <div className={styles.matchTeamScoreGB}>
                {match.status == "U"
                  ? null
                  : match.home_goals + "." + match.home_behinds}
              </div>
              <div className={styles.matchTeamScoreTotal}>
                {match.home_score}
              </div>
            </div>
          </div>
          <div
            className={[
              styles.matchTeamScoreline,
              match.middle == "lt" ? styles.winner : "",
            ].join(" ")}
          >
            <div className={`w-4 h-4`}>
              <TeamLogo teamAbbrev={match.away_team} width={16} height={16} />
            </div>
            <div className={styles.matchTeamName}>{match.away_team}</div>
            <div className={styles.matchTeamScore}>
              <div className={styles.matchTeamScoreGB}>
                {match.status == "U"
                  ? null
                  : match.away_goals + "." + match.away_behinds}
              </div>
              <div className={styles.matchTeamScoreTotal}>
                {match.away_score}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
