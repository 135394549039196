"use client";

import {
  SessionProvider as NextAuthSessionProvider,
  type SessionProviderProps as NextAuthSessionProviderProps,
} from "next-auth/react";

export function SessionContextProvider(props: NextAuthSessionProviderProps) {
  return (
    <NextAuthSessionProvider session={props.session}>
      {props.children}
    </NextAuthSessionProvider>
  );
}
