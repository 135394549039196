"use client";

import { useState } from "react";
import { createContext } from "react";

export const ScoreTickerContext = createContext<{
  roundId: number | null;
  setRoundId: (roundId: number) => void;
}>({
  roundId: null,
  setRoundId: () => {},
});

export function ScoreTickerContextProvider({ children }) {
  const [roundId, setRoundId] = useState<number | null>(null);

  return (
    <ScoreTickerContext.Provider value={{ roundId, setRoundId }}>
      {children}
    </ScoreTickerContext.Provider>
  );
}
