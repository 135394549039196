"use client";

import styles from "./score-ticker.module.scss";
import ScoreTickerMatch from "./score-ticker-match";
import useSWR from "swr";
import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

import Link from "next/link";
import { getApiUrl } from "@/lib/url";
import { ScoreTickerContext } from "@/features/score-ticker/context/score-ticker-context";

const ROUND_SUMMARY_URL = `${getApiUrl()}/round_summary`;

export const ScoreTicker = () => {
  const { roundId, setRoundId } = useContext(ScoreTickerContext);

  const fetcher = (url: string) => fetch(url).then((res) => res.json());
  const { data, error } = useSWR(
    getRoundSummaryUrl(roundId || undefined),
    fetcher
  );

  if (error) {
    return null;
  }

  const prevRoundId = data ? data.prev_round_id || null : null;
  const nextRoundId = data ? data.next_round_id || null : null;

  return (
    <div className={`max-w-7xl h-full m-auto bg-white`}>
      <div className={`flex flex-row justify-start m-auto h-full w-full`}>
        <button
          onClick={() => {
            prevRoundId ? setRoundId(prevRoundId) : null;
          }}
          className={`h-17 bg-slate-600 text-gray-300 hover:bg-slate-500 w-6`}
        >
          {prevRoundId ? <FontAwesomeIcon icon={faAngleLeft} /> : null}
        </button>
        <div className={styles.round}>
          <div className={styles.competition}>
            {data ? data.competition_type_name : null}
          </div>
          <div className={styles.roundName}>
            {data ? data.round_normal_name : null}
          </div>
        </div>
        <div className={`flex flex-nowrap overflow-x-auto w-full`}>
          {data ? (
            data.matches.map((match) => (
              <ScoreTickerMatch match={match} key={match.id} />
            ))
          ) : (
            <div className={`self-center justify-self-center m-auto`}>
              <FontAwesomeIcon icon={faSpinner} spin size={"2x"} />
            </div>
          )}
        </div>
        <button
          onClick={() => (nextRoundId ? setRoundId(nextRoundId) : null)}
          className={`h-17 bg-slate-600 text-gray-300 hover:bg-slate-500 w-6`}
        >
          {nextRoundId ? <FontAwesomeIcon icon={faAngleRight} /> : null}
        </button>
      </div>
    </div>
  );
};

function getRoundSummaryUrl(roundId?: number) {
  if (!roundId) {
    return ROUND_SUMMARY_URL;
  } else {
    return ROUND_SUMMARY_URL + "?round_id=" + roundId;
  }
}
