import(/* webpackMode: "eager" */ "/app/app/(main)/layout.module.scss");
import(/* webpackMode: "eager" */ "/app/app/globals.css");
import(/* webpackMode: "eager" */ "/app/features/auth/components/sign-in-button.tsx");
import(/* webpackMode: "eager" */ "/app/features/auth/context/session-context.tsx");
import(/* webpackMode: "eager" */ "/app/features/score-ticker/components/score-ticker.tsx");
import(/* webpackMode: "eager" */ "/app/features/score-ticker/components/update-ticker.tsx");
import(/* webpackMode: "eager" */ "/app/features/score-ticker/context/score-ticker-context.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/(main)/layout.tsx\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-roboto-mono\"}],\"variableName\":\"robotoMono\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/(main)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}")